export function dateFormatter(
  timestamp: string | number | Date | undefined,
  locale: string
): string {
  if (!timestamp) {
    // Handle the case where timestamp is undefined
    return 'Invalid date'
  }

  let dateFormat: string

  switch (locale) {
    case 'en':
      dateFormat = 'en-US'
      break
    case 'sr':
      dateFormat = 'sr-Latn-RS'
      break
    case 'de':
      dateFormat = 'de-DE'
      break
    case 'nl':
      dateFormat = 'nl-NL'
      break
    case 'es':
      dateFormat = 'es-ES'
      break
    default:
      dateFormat = 'sr-Latn-RS' // Default locale
      break
  }

  const date = new Date(timestamp)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    // second: '2-digit',
    hour12: false, // Whether to use 12-hour or 24-hour format
    timeZone: 'UTC', // Specify the time zone if needed
  }

  // https://localizely.com/locale-code/sr-Latn-RS/

  const formatter = new Intl.DateTimeFormat(dateFormat, options)
  const formattedDateTime = formatter.format(date)
  return formattedDateTime
  // return new Date(timestamp).toLocaleString()
}
